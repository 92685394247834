.App {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.chart {
  margin-top: 20px;
}

.headline {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}